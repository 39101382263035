import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Image from '../../../image/image';

export default function VideoCard(props) {
  const {
    config,
    metadata,
    cardEvent,
    hasLink,
    defaultHeight,
    defaultWidth,
    noLink,
    sliderCardClickedCallBack
  } = props;

  const videoState = metadata.isVideoActive
    ? `card--video-active`
    : `card--video-play`;

  const content = [
    <Image
      key={metadata.featured_image.source}
      alt={metadata.title}
      src={metadata.featured_image.source}
      assetSizes={metadata.assetSizes}
      jwId={metadata.id}
      defaultHeight={defaultHeight}
      defaultWidth={defaultWidth}
    />,
    <figcaption key={metadata.title}>
      <h2 className="card__title card__title--video">{metadata.title}</h2>
    </figcaption>
  ];

  return (
    <figure
      className={`card card--video ${videoState}`}
      onClick={event => {
        cardEvent(event, metadata.index);
        sliderCardClickedCallBack(metadata);
      }}
    >
      {hasLink && !noLink ? (
        <FCLink
          config={config}
          to={metadata.link}
          title={metadata.title}
          externalLink={metadata.external}
          targetWindow={metadata.targetWindow}
        >
          {content}
        </FCLink>
      ) : (
        content
      )}
    </figure>
  );
}

VideoCard.defaultProps = {
  cardEvent: () => {},
  hasLink: true,
  defaultHeight: '',
  defaultWidth: '',
  noLink: false,
  sliderCardClickedCallBack: () => {}
};

VideoCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  hasLink: PropTypes.bool,
  defaultHeight: PropTypes.string,
  defaultWidth: PropTypes.string,
  noLink: PropTypes.bool,
  sliderCardClickedCallBack: PropTypes.func
};
