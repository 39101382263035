/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Eyebrow from '../../../eyebrow/eyebrow';
import Poster from '../../../poster/poster';
import { isAdvertorial, isPremium } from '../../../advertorial/advertorial';

export default function InjectionCard(props) {
  const {
    metadata,
    config,
    cardEvent,
    disabledImage,
    fullPageAdvertorial
  } = props;
  const advertorial = isAdvertorial(metadata.tags) && !fullPageAdvertorial;
  const isPremiumArticle = isPremium(metadata.tags);
  const image = metadata.featured_image?.source;
  const hasImage = image && !disabledImage;
  const alwaysOn = metadata.alwaysOnImage;

  const setCardClasses = () => {
    const cardClasses = ['card', 'card--injection'];

    if (alwaysOn) {
      cardClasses.push('card--alwaysOn');
    }

    if (advertorial) {
      cardClasses.push('card--advertorial');
    }

    return cardClasses.join(' ');
  };

  const setPosterClasses = () => {
    const posterClasses = ['card__poster', 'card__poster--injection'];

    if (metadata.hasVideoPoster) {
      posterClasses.push('card__poster--video');
    }

    return posterClasses.join(' ');
  };

  if (!alwaysOn) {
    return (
      <article
        className={setCardClasses()}
        onClick={cardEvent}
        style={{ paddingRight: '20px', paddingBottom: '20px' }}
      >
        <FCLink
          currentSite={metadata.currentSite}
          to={metadata.link}
          title={metadata.title}
          config={config}
          externalLink={metadata.external}
          targetWindow={metadata.targetWindow}
        >
          {hasImage ? (
            <Poster
              metadata={metadata}
              className={setPosterClasses()}
              generateClasses={false}
            />
          ) : null}

          <div className="card__text-wrapper card__text-wrapper--injection">
            <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
            <h2 className="card__title card__title--injection">
              {metadata.title}
            </h2>

            {hasImage ? (
              <p className="card__deck card__deck--injection">
                {metadata.excerpt}
              </p>
            ) : null}
          </div>
        </FCLink>
      </article>
    );
  } else {
    return (
      <article
        className={setCardClasses()}
        onClick={cardEvent}
        style={{ paddingRight: '20px', paddingBottom: '20px' }}
      >
        <FCLink
          currentSite={metadata.currentSite}
          to={`${metadata.clickthroughPrefix}${metadata.clickthroughURL}`}
          title={metadata.title}
          config={config}
          externalLink={metadata.external}
          targetWindow={metadata.targetWindow}
        >
          <div
            style={{
              backgroundImage: `url(${alwaysOn})`,
              height: '180px',
              width: '100%',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              margin: '0 auto',
              backgroundRepeat: 'no-repeat'
            }}
          />
        </FCLink>
      </article>
    );
  }
}

InjectionCard.defaultProps = {
  cardEvent: () => {},
  fullPageAdvertorial: false,
  disabledImage: false
};

InjectionCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  fullPageAdvertorial: PropTypes.bool,
  disabledImage: PropTypes.bool
};
