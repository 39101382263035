import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const Button = ({ label, onClick, rounded, bgColor, hoverColor, labelColor, labelHoverColor, uppercase, version = 'default' }) => {
  const initialBackground = bgColor || '#777777';
  const initialLabelColor = labelColor || '#ffffff';
  const [background, setBackground] = useState(initialBackground)
  const [textColor, setTextColor] = useState(initialLabelColor)
  const [isHovered, setIsHovered] = useState(false);

  let modifier = rounded ? 'rounded' : version;
  if (version) modifier = version;
  const isArrow = modifier === 'arrow';

  return (
    <button
      className={`button__base ${rounded ? 'button__base--rounded' : 'button__base--default'}`}
      onClick={onClick}
      onMouseEnter={() => {
        setBackground(hoverColor || '#000000')
        setTextColor(labelHoverColor || '#000000')
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setBackground(initialBackground)
        setTextColor(initialLabelColor)
        setIsHovered(false);
      }}
      style={{
        backgroundColor: isHovered ? hoverColor : background,
        color: isHovered ? labelHoverColor : textColor,
        textTransform: uppercase || null
      }}
    >
      {label}
      {isArrow && 
      <svg width={10} height={11.236} viewBox="0 0 10 11.236" className="icon-full-arrow-up">
        <path d="M10 4.989L5.011 0 0 4.987l1.176 1.18 3.833-3.813 3.813 3.813z" fill={isHovered ? '#000': '#ED5C30'} className="head" />
        <path d="M5 1v10.236" fill="none" stroke={isHovered ? '#000': '#ED5C30'} strokeWidth={1.5} className="body" />
      </svg>}
    </button>
  )
};

Button.propTypes = {
  label: PropTypes.string,
  bgColor: PropTypes.string,
  labelColor: PropTypes.string,
  labelHoverColor: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  font: PropTypes.string,
  letterSpacing: PropTypes.string,
  uppercase: PropTypes.string
}

Button.defaultProps = {
  label: 'Button',
  onClick: () => {console.log('Button, click!')}
}