import React from 'react';
import PropTypes from 'prop-types';

export const Image = ({
  image,
  noBorder,
  alt,
  width,
  float,
  caption,
  captionObj = null,
  flex,
  noPaddingBottom,
  addClass
}) => {
  if (!image) return false;

  return (
    <div
      className={`image-container ${flex || caption ? 'image-container--flex' : ''}  ${addClass ? `${addClass}` : ''}`}
    >
      <div className={`image-display ${noBorder ? '' : 'withBorder'} ${float ? `float ${float}` : ''} ${noPaddingBottom ? `pb-0` : ''}`}>
        <img alt={alt || ""} src={`${image}`} {...width && { style: { maxWidth: width } }} />
      </div>
      {caption && (
        <div className="image-caption" dangerouslySetInnerHTML={{__html: caption}}></div>
      )}
      {captionObj}
    </div>
  );
};

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBorder: PropTypes.bool,
  width: PropTypes.string,
  float: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  flex: PropTypes.bool,
  customStyles: PropTypes.object,
  addClass: PropTypes.string
};

Image.defaultProps = {
  image: "https://images.fastcompany.net/image/upload/v1625086207/fcweb/example_profile_card_utr08f.jpg",
  noBorder: true,
  width: '100%',
  float: null,
  alt: '',
  caption: null,
  flex: false
};
