import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  makeHttps,
  changeAssetDomain,
  convertGIFToVideoAssets,
  buildAssetUrlWithPresets
} from '../../../utils/cloudinary_asset_util';
import matchMediaDesktop from '../../../utils/client_util';

const GifConverter = ({ assetSizes, src }) => {
  const isDesktop = matchMediaDesktop();
  const secureSrc = makeHttps(src);
  const fcDomainAsset = changeAssetDomain(secureSrc);
  const assetsWithPresets = buildAssetUrlWithPresets(
    fcDomainAsset,
    assetSizes,
    isDesktop
  );
  const videoFormats = convertGIFToVideoAssets(assetsWithPresets);

  return (
    <div className="video video--converted">
      <video
        className="video"
        poster={get(videoFormats, 'webm')}
        playsInline
        loop
        preload="auto"
        autoPlay
        muted
      >
        <source type="video/webm" src={get(videoFormats, 'webm')} />
        <source type="video/mp4" src={get(videoFormats, 'mp4')} />
        <track kind="captions" />
      </video>
    </div>
  );
};

GifConverter.defaultProps = {
  assetSizes: {}
};

GifConverter.propTypes = {
  src: PropTypes.string.isRequired,
  assetSizes: PropTypes.shape({
    large: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.arrayOf(PropTypes.string)
    }),
    small: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

export default GifConverter;
