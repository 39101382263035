import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Image from '../image/image';
import GifConverter from '../gif_converter/gif_converter';
import { createPosterClasses } from '../../../utils/create_class_list_util';

export default function Poster(props) {
  const {
    metadata,
    className,
    generateClasses,
    loading,
    fetchpriority
  } = props;
  const image =
    get(metadata, 'featured_image.source') ||
    get(metadata, 'image') ||
    get(metadata, 'posterImage');
  const cerosEmbed = get(metadata, 'customFields.ceros_embed', false);
  const fullPageCeros = get(
    metadata,
    'customFields.full_page_ceros_embed',
    false
  );
  const verticalImg = get(metadata, 'featured_image.verticalImg', false);
  if (
    (metadata.allowNoImage && !image) ||
    (metadata.layout === 'short' && metadata.hasVideoPoster === false)
  ) {
    return null;
  }

  const assignedImage =
    image ||
    'https://images.fastcompany.net/image/upload/v1485990651/fcweb/fastcompanyimagedefault.jpg';
  let posterClasses = className;
  if (generateClasses) {
    posterClasses = createPosterClasses(
      metadata.tags,
      metadata.events,
      className,
      metadata.hasVideoPoster,
      metadata.isPlayerVideo
    );
  }
  const isGif = /.gif$/.test(assignedImage);
  let markup = (
    <Image
      alt={metadata.title}
      src={assignedImage}
      noLazy={metadata.noLazy}
      verticalImg={verticalImg}
      assetSizes={metadata.assetSizes}
      baseClass="aspect-ratio-image"
      aspectRatioImage={true}
      loading={loading}
      fetchpriority={fetchpriority}
    />
  );

  if (isGif) {
    markup = (
      <GifConverter
        alt={metadata.title}
        src={assignedImage}
        noLazy={metadata.noLazy}
        assetSizes={metadata.assetSizes}
      />
    );
  }

  if (!fullPageCeros && cerosEmbed && className.includes('featured')) {
    return (
      <figure className={`${posterClasses} card__poster__ceros`}>
        <div dangerouslySetInnerHTML={{ __html: cerosEmbed }} />
      </figure>
    );
  }

  return <figure className={posterClasses}>{markup}</figure>;
}

// NOTE: generateClasses will be removed once we kill createPosterClasses
Poster.defaultProps = {
  className: '',
  generateClasses: true,
  loading: '',
  fetchpriority: 'auto'
};

Poster.propTypes = {
  metadata: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  generateClasses: PropTypes.bool,
  loading: PropTypes.string,
  fetchpriority: PropTypes.string
};
