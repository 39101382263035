import React from 'react';
import PropTypes from 'prop-types';

export const Eyebrow = ({ children }) => (
  <div className="eyebrow__text">{children}</div>
)

Eyebrow.propTypes = {
  children: PropTypes.any,
}
