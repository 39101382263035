import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isAdvertorial, isFestival } from '../advertorial/advertorial';
import { getPriorityTag } from '../../../utils/ads/get_priority_tag';
import loadable from '@loadable/component';

const Tooltip = loadable(() => import('../../fc-components/main/components'), {
  resolveComponent: components => components.Tooltip
});
const ConditionalWrapper = loadable(() =>
  import('../../fc-components/main/utils/ConditionalWrapper')
);

function showLive(live) {
  if (live) {
    return (
      <li className="eyebrow__item eyebrow__item--live">
        <span className="eyebrow__live">Live</span>
      </li>
    );
  }

  return null;
}

function getText(tags, supertags) {
  let slug = getPriorityTag(tags, supertags);

  // If no slug, bail on display of tag
  if (!slug) {
    return '';
  }

  let name = slug.replace(/-/gi, ' ');
  let link;

  const formattedName = name
    .replace(/\*/g, '')
    .replace(/_/g, ' ')
    .replace(/\s?advertorial/g, '')
    .trim();

  name = (() => {
    switch (formattedName) {
      case 'usa can this brand be saved':
        return 'USA: Can This Brand Be Saved?';

      case 'LOreal':
        return "L'Oreal";

      case 'ATT':
        return 'AT&T';

      case 'Square Grow Online':
      case 'Square Large Seller':
        return 'Square';
      case 'the coca cola company':
        return 'THE COCA-COLA COMPANY';

      default:
        return formattedName;
    }
  })();

  if (slug.includes('_advertorial')) {
    slug = slug
      .replace(/[*&]/g, '')
      .replace(/\s?_advertorial/g, '')
      .toLowerCase()
      .trim();
    link = `/fcw/${slug}`;
  } else {
    link = `/section/${slug}`;
  }
  if (link === '/section/most-creative-people') {
    link = '/most-creative-people';
  } else if (link.includes('ac-hotels')) {
    link = 'https://www.fastcompany.com/fcw/AC_Hotels_by_Marriott';
  } else if (link.includes('mckinsey')) {
    link = '/fcw/mckinsey__company';
  } else if (link === '/section/coronavirus-crisis') {
    link = '/section/coronavirus';
  } else if (link === '/fcw/the coca-cola company') {
    link = '/fcw/the coca cola company';
  }
  return { name, link };
}

function renderTag(tags, post, supertags) {
  const data = getText(tags, supertags);

  if (data) {
    const { name, link } = data;
    let finalName = name;
    const finalLink = link;

    // Exceptions by article ID
    switch (post.id) {
      // case 90769390:
      //   finalName = 'Tech';
      //   finalLink = '/section/tech';
      //   break;
      default:
        break;
    }

    // Exceptions by tag name
    if (typeof finalName === 'string') {
      finalName = finalName.replace('covid 19', 'COVID-19');
    }

    return (
      <li className="eyebrow__item">
        <a href={finalLink}>{finalName}</a>
      </li>
    );
  }
  return null;
}

function shouldShowTime(post) {
  const showTime = get(post, 'showTime');
  if (!showTime) return null;
  return <li className="eyebrow__item">{get(post, 'timePublished')} </li>;
}

export default function Eyebrow(props) {
  if (!props) {
    return null;
  }

  const {
    metadata,
    post,
    tags,
    modifier,
    live,
    insert,
    supertags,
    showIcon
  } = props;

  if (!isEmpty(metadata)) {
    const cardTags = metadata.tags || [];
    let category = get(metadata, 'categories[0].name');
    let categorySlug = get(metadata, 'categories[0].slug');

    // Handles coDesign posts category, if there's a tag that matches older legacy categories, use that instead
    if (category === 'Co.Design' && get(metadata, 'categories[1].name')) {
      category = get(metadata, 'categories[1].name');
      categorySlug = get(metadata, 'categories[1].slug');
    } else if (category === 'Impact') {
      categorySlug = 'impact';
    } else if (category === 'Work Life') {
      categorySlug = 'work-life';
    }

    let eyebrow = category || null;

    let eyebrowLink = (() => {
      if (!categorySlug) {
        return null;
      }

      if (categorySlug?.startsWith('https://')) {
        return categorySlug;
      }

      return `/${categorySlug}`;
    })();

    if (!eyebrow && metadata.dateFormatted && metadata.timePublished) {
      eyebrow = `${metadata.dateFormatted} | ${metadata.timePublished}`;
      eyebrowLink = null;
    }

    if (isAdvertorial(cardTags) || isFestival(cardTags)) {
      const data = getText(cardTags);
      if (data) {
        const { name, link } = data;
        eyebrow = `Presented By ${name}`;
        eyebrowLink = link;
      }
    }

    if (get(metadata, 'isSponsoredTile')) {
      eyebrow = '';
      eyebrowLink = null;
    }

    // When it's a News story, it has category Uncategorized
    // we don't want to show that to the user, use date instead
    if (eyebrow === 'Uncategorized') {
      eyebrow = metadata.timePublished;
      eyebrowLink = null;
    }

    if (metadata.customEyebrowText) {
      if (metadata.fullyReplaceEyebrow) {
        eyebrow = metadata.customEyebrowText;
        eyebrowLink = null;
      } else {
        eyebrow = isAdvertorial(cardTags)
          ? `Presented by ${metadata.customEyebrowText}`
          : metadata.customEyebrowText;
        eyebrowLink = null;
      }
    }

    return (
      eyebrow && (
        <div className={clsx('eyebrow', `eyebrow--${modifier}`)}>
          {showIcon && (
            <Tooltip text="Fast Company Premium provides subscribers with access to exclusive reporting and analysis on emerging trends in technology, business innovation, future of work, and design.">
              <span className="premium-icon" />
            </Tooltip>
          )}
          <span className={clsx(showIcon && 'left-margin')}>
            <ConditionalWrapper
              condition={eyebrowLink}
              wrapper={children => (
                <a
                  href={eyebrowLink}
                  style={{ color: '#868686', display: 'inline' }}
                >
                  {children}
                </a>
              )}
            >
              {eyebrow}
            </ConditionalWrapper>
          </span>
        </div>
      )
    );
  }

  if (!isEmpty(post)) {
    let finalDate = get(post, 'dateFormatted');
    const dateStandardUS = finalDate.replace(/\./g, '-');
    finalDate = dateStandardUS;
    const postCategories = get(post, 'categories', []);
    const categoryName = get(postCategories, '[0].name', '');
    const categorySlug = get(postCategories, '[0].slug', '');
    const layout = get(post, 'layout');

    return (
      <ul className={insert ? 'eyebrow article__preview__eyebrow' : 'eyebrow'}>
        {showLive(live)}
        <li
          className={
            insert
              ? 'eyebrow__item article__preview__eyebrow__item'
              : 'eyebrow__item'
          }
        >
          {layout === 'redesign' ? (
            <div className="redesign--eyebrow">
              <a href={`/${categorySlug}`}>{categoryName}</a>
            </div>
          ) : (
            <time dateTime={get(post, 'structuredData.published')}>
              {finalDate}
            </time>
          )}
        </li>
        {shouldShowTime(post)}
        {renderTag(tags, post, supertags)}
      </ul>
    );
  }

  return null;
}

Eyebrow.defaultProps = {
  post: {},
  metadata: {},
  modifier: '',
  live: false,
  insert: false,
  supertags: [],
  showIcon: false
};

Eyebrow.propTypes = {
  post: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  metadata: PropTypes.shape({}),
  modifier: PropTypes.string,
  live: PropTypes.bool,
  insert: PropTypes.bool,
  supertags: PropTypes.arrayOf(PropTypes.string),
  showIcon: PropTypes.bool
};
