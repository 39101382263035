import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Image from '../../../image/image';
import { imageSizesLookup } from '../../../../../utils/cloudinary_asset_util';

export default function Magazine(props) {
  const { config, metadata, cardEvent, hasTitle, fullWidth, fullIssue } = props;

  const setCardClasses = () => {
    const cardClasses = ['card', 'card--magazine'];

    if (fullWidth) {
      cardClasses.push(`card--full-width`);
    }

    return cardClasses.join(' ');
  };

  if (!metadata.title || !metadata.image) {
    return null;
  }

  return (
    <figure className={setCardClasses()} onClick={event => cardEvent(event)}>
      <FCLink
        config={config}
        to={`https://www.fastcompany.com/magazine/${metadata.slug}`}
        title={metadata.title}
        externalLink={false}
        targetWindow={metadata.targetWindow}
      >
        <Image
          src={metadata.image}
          alt={metadata.title}
          assetSizes={imageSizesLookup.magazine}
        />
        {hasTitle ? (
          <figcaption>
            <h2 className="card__title card__title--magazine">
              {metadata.title}
            </h2>
          </figcaption>
        ) : null}
      </FCLink>
      <div className="card__issue-text">
        <time className="card__issue-date">{metadata.date}</time>
        <ul className="card__links">
          {fullIssue ? (
            <li className="card__links-item">
              <FCLink
                config={config}
                to={`https://www.fastcompany.com/magazine/${metadata.slug}`}
                title={metadata.title}
                externalLink={false}
              >
                Full Issue
              </FCLink>
            </li>
          ) : null}
          {metadata.pdf_url ? (
            <li className="card__links-item">
              <a
                href={metadata.pdf_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                View PDF
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    </figure>
  );
}

Magazine.defaultProps = {
  cardEvent: () => {},
  fullWidth: false,
  hasTitle: true,
  fullIssue: true
};

Magazine.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  fullWidth: PropTypes.bool,
  hasTitle: PropTypes.bool,
  fullIssue: PropTypes.bool
};
