/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
// import FCLink from '../../../fc_link/fc_link';
import Poster from '../../../poster/poster';
import TagPoster from '../../../poster/tag_poster';
import Eyebrow from '../../../eyebrow/eyebrow';
// import { isAdvertorial, isPremium } from '../../../advertorial/advertorial';
import { isPremium } from '../../../advertorial/advertorial';
// import BrandworksAnimation from '../brandworks_animation/brandworks_animation';
import clsx from 'clsx';

const DefaultCard = ({
  // noBottomBorder,
  // noBorder,
  // top,
  // totalCards,
  metadata,
  // align,
  posterType,
  noPremiumIcon,
  // fullPageAdvertorial,
  cardEvent = () => {}
}) => {
  // const { cardType, tags, allowTextOnly, events, buttonText } = metadata;
  const { tags, events, buttonText, link, title, articleHeadline } = metadata;
  // const advertorial = isAdvertorial(tags) && !fullPageAdvertorial;
  const isPremiumArticle = !noPremiumIcon && isPremium(tags);
  // const textOnlyCard = allowTextOnly && !metadata.featured_image.source;

  return (
    <article
      className={clsx('card card--default', {
        // card__hiring: true
      })}
      onClick={event => cardEvent(event)}
    >
      <div className="card__container">
        <div className="card__poster__container">
          {posterType === 'tag' ? (
            <TagPoster
              metadata={metadata}
              className="card__poster card__poster--default"
              generateClasses={false}
            />
          ) : (
            <a href={link}>
              <Poster
                metadata={metadata}
                className="card__poster card__poster--default"
                generateClasses={false}
              />
            </a>
          )}
        </div>
        <div className="card__text-wrapper">
          <div className="card__eyebrow">
            <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
          </div>
          <a href={link}>
            <span className="card__title">{title || articleHeadline}</span>
          </a>
          {events && (
            <button className="card__button">
              {buttonText || 'Learn More'}
            </button>
          )}
        </div>
      </div>
    </article>
  );
};

DefaultCard.defaultProps = {
  cardEvent: () => {},
  // fullPageAdvertorial: false,
  // totalCards: 0,
  // noBottomBorder: false,
  // align: null,
  // top: false,
  posterType: 'default',
  // noBorder: false,
  noPremiumIcon: false
};

DefaultCard.propTypes = {
  metadata: PropTypes.shape({}).isRequired,
  // fullPageAdvertorial: PropTypes.bool,
  cardEvent: PropTypes.func,
  // totalCards: PropTypes.number,
  // noBottomBorder: PropTypes.bool,
  // align: PropTypes.string,
  // top: PropTypes.bool,
  posterType: PropTypes.string,
  // noBorder: PropTypes.bool,
  noPremiumIcon: PropTypes.bool
};

export default DefaultCard;
