import React from 'react';
import PropTypes from 'prop-types';
import {
  Eyebrow,
  H2,
  Image,
  Button
} from '../../../../fc-components/main/components';

const Audio = ({
  eyebrow,
  title,
  excerpt,
  image,
  link,
  hideExcerpt = false,
  audio
}) => (
  <article className="card card__audio">
    <a className="card__container" href={link} target="_blank">
      {image && (
        <div className="card__image">
          <Image image={image} />
        </div>
      )}
      <div className="card__meta">
        {eyebrow && (
          <Eyebrow large>
            <span className="card__meta__eyebrow">{eyebrow}</span>
          </Eyebrow>
        )}
        <H2 className="card__meta__title">{title}</H2>
        {excerpt &&
          !hideExcerpt && (
            <div
              className="card__meta__excerpt"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          )}
      </div>
    </a>
    <audio controls className="card__player"> {/*eslint-disable-line*/}
      <source src={audio} type="audio/mpeg" /> {/*eslint-disable-line*/}
    </audio> {/*eslint-disable-line*/}
    <a className="card__audio__link" href={link}>
      <Button
        version="arrow"
        label="Read More"
        labelColor="#ED5C30"
        labelHoverColor="#000"
        hoverColor="transparent"
        bgColor=""
      />
    </a>
  </article>
);

Audio.propTypes = {
  title: PropTypes.string.isRequired,
  eyebrow: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  hideExcerpt: PropTypes.bool.isRequired,
  audio: PropTypes.string.isRequired
};

export default Audio;
