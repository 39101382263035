import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Poster from '../../../poster/poster';
import Eyebrow from '../../../eyebrow/eyebrow';
import { isPremium } from '../../../advertorial/advertorial';
import clsx from 'clsx';

const FeaturedCard = ({ config, metadata, cardEvent, loading }) => {
  const {
    hasVideoPoster,
    tags,
    title,
    excerpt,
    link,
    external,
    source,
    targetWindow,
    featured_image: featuredImage,
    allowTextOnly
  } = metadata;
  const noImage = !featuredImage || !featuredImage.source;
  const textOnlyCard = allowTextOnly && noImage;
  const isPremiumArticle = isPremium(tags || []);

  const setPosterClasses = () => {
    const posterClasses = ['card__poster', 'card__poster--featured'];

    if (hasVideoPoster) {
      posterClasses.push('card__poster--video');
    }

    return posterClasses.join(' ');
  };

  return (
    <article
      className={clsx('card card--featured', {
        'card--text': textOnlyCard
      })}
      onClick={event => cardEvent(event)}
    >
      <div className="card__container">
        <div className="card__poster__container">
          <FCLink
            to={link}
            title={title}
            config={config}
            externalLink={external || source === 'external'}
            targetWindow={targetWindow}
          >
            <Poster
              metadata={metadata}
              className={setPosterClasses()}
              generateClasses={false}
              loading={loading}
              fetchpriority="high"
            />
          </FCLink>
        </div>
        <div
          className={clsx('card__text-wrapper', {
            'card__text-wrapper--text-only': textOnlyCard
          })}
        >
          <div className="card__eyebrow">
            <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
          </div>
          <FCLink
            to={link}
            title={title}
            config={config}
            externalLink={external || source === 'external'}
            targetWindow={targetWindow}
          >
            <h2
              className={`card__title card__title--featured ${
                textOnlyCard ? 'card__title--only' : ''
              }`}
            >
              {title || ''}
            </h2>
          </FCLink>
          {isPremiumArticle && (
            <h3 className="card__deck card__deck--featured">{excerpt || ''}</h3>
          )}
        </div>
      </div>
    </article>
  );
};

FeaturedCard.defaultProps = {
  cardEvent: () => {},
  loading: ''
};

FeaturedCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  loading: PropTypes.string
};

export default FeaturedCard;
