import React from 'react';
import PropTypes from 'prop-types';

export const H2 = ({ children, className, ...props }) => {
  return (
    <h2
      className={`h2 ${className || ''}`}
      style={{...props}}
    >
      {children}
    </h2>
  )
};