import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from '../../../../../utils/comma_number_util';
import { get, isEmpty } from 'lodash';

export default function GiftCard(props) {
  const { metadata } = props;
  const { image, name, description, url, price } = metadata;

  let imageUrl = image || '';
  if (!isEmpty(get(metadata, 'image_upload', ''))) {
    imageUrl = get(metadata, 'image_upload.sizes.large', image);
  }

  return (
    <div className="card card--gift">
      <div className="card--gift__image-container">
        {imageUrl && (
          <a href={url} target="_blank" rel="nofollow sponsored">
            <img src={imageUrl} alt={name} />
          </a>
        )}
      </div>
      <a href={url} target="_blank" rel="nofollow sponsored">
        <p className="card--gift__title">{name}</p>
      </a>
      <a href={url} target="_blank" rel="nofollow sponsored">
        <div
          className="card--gift__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </a>
      <div className="price-buy">
        <a href={url} target="_blank" rel="nofollow sponsored">
          <span>{commaNumber(price)}</span>
        </a>
        <span className="pipe">|</span>
        <a href={url} target="_blank" className="buy" rel="nofollow sponsored">
          Buy Now
        </a>
      </div>
    </div>
  );
}

GiftCard.propTypes = {
  metadata: PropTypes.shape({}).isRequired
};
