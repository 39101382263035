import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';
import {
  buildSrcSet,
  makeHttps,
  changeAssetDomain
} from '../../../utils/cloudinary_asset_util';

export default class Image extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      alt,
      src,
      assetSizes,
      noLazy,
      baseClass,
      modifier,
      jwId,
      aspectRatioImage,
      loading,
      verticalImg,
      defaultWidth,
      defaultHeight,
      caption,
      fetchpriority
    } = this.props;

    const bUrlCloudinaryV3 =
      'wp-cms/uploads/https://fast-company-res.cloudinary.com/image/upload/';
    let actualSrc = src;
    if (verticalImg) {
      actualSrc = verticalImg;
    }
    const secureSrc = !actualSrc.includes('jwplayer')
      ? makeHttps(actualSrc)
          .replace(bUrlCloudinaryV3, '')
          .replace(/\.jpg$|\.png$/, '.webp')
      : makeHttps(actualSrc);
    const fcDomainAsset = changeAssetDomain(secureSrc);

    let sourceAttribs = {
      media: '(max-width: 40em)',
      'data-sizes': get(assetSizes, 'small.sizes'),
      'data-srcset': buildSrcSet(assetSizes, 'small.srcset', fcDomainAsset)
    };

    let imgAttribs = {
      'data-sizes': get(assetSizes, 'large.sizes'),
      'data-srcset': buildSrcSet(assetSizes, 'large.srcset', fcDomainAsset),
      className: `${
        noLazy ? '' : 'lazyload'
      } ${baseClass} ${baseClass}--${modifier}`,
      loading,
      fetchpriority
    };

    if (jwId) {
      const index = fcDomainAsset.indexOf('https://cdn.jwplayer.com/thumbs/');
      if (index) {
        const jwUrl = fcDomainAsset.slice(index);
        sourceAttribs['data-srcset'] = jwUrl.replace(jwId, `${jwId}-320`);
        imgAttribs['data-srcset'] = jwUrl.replace(jwId, `${jwId}-320`);
      }
    }

    try {
      if (aspectRatioImage) {
        const largeImages = get(assetSizes, 'large.srcset', []);
        if (largeImages) {
          const largestImage = largeImages[largeImages.length - 1];
          const imageOptions = largestImage.split(',');
          if (imageOptions) {
            let ratioWidth = 16;
            let ratioHeight = 9;
            if (imageOptions[1].includes('ar_')) {
              const imageRatioOption = imageOptions[0].split('_');
              if (imageRatioOption[1].includes(':')) {
                const ratio = imageRatioOption[1].split(':');
                [ratioWidth, ratioHeight] = ratio;
              }
            }
            if (imageOptions[0].includes('w_')) {
              const imageSizeOption = imageOptions[0].split('_');
              const width = imageSizeOption[1];
              const height = Math.round(ratioHeight / ratioWidth * width);
              imgAttribs.width = width;
              imgAttribs.height = height;
            }
          }
        }
      }
    } catch (err) {
      console.warn(`Error loading image: ${err}`); // eslint-disable-line no-console
    }

    // if (width && height) {
    //   imgAttribs.width = width;
    //   imgAttribs.height = height;
    // }

    // if (!noLazy) {
    //   imgAttribs.loading = 'lazy';
    // }

    function removeData(str) {
      return str.replace('data-', '');
    }

    function removeDataAttrs(obj) {
      const correctedParams = assign({}, obj);
      // eslint-disable-next-line no-restricted-syntax
      for (const attr in correctedParams) {
        if (/^data-/.test(attr)) {
          let strippedAttr = removeData(attr);
          strippedAttr = strippedAttr.replace('srcset', 'srcSet');
          correctedParams[strippedAttr] = correctedParams[attr];
          delete correctedParams[attr];
        }
      }

      return correctedParams;
    }

    if (noLazy) {
      imgAttribs = removeDataAttrs(imgAttribs);
      sourceAttribs = removeDataAttrs(sourceAttribs);
    }

    // Explicitly set width if not provided (CWV)
    if (!('width' in imgAttribs)) {
      imgAttribs.width = '640';
      imgAttribs['data-explicit-width'] = imgAttribs.width;
    }

    // Explicitly set height if not provided (CWV)
    if (!('height' in imgAttribs)) {
      imgAttribs.height = '360';
      imgAttribs['data-explicit-height'] = imgAttribs.height;
    }

    if (src && !isEmpty(assetSizes) && !verticalImg) {
      return (
        <Fragment>
          <picture>
            <source
              media={sourceAttribs.media}
              sizes={sourceAttribs.sizes}
              srcSet={sourceAttribs.srcset}
            />
            {noLazy ? (
              <img {...imgAttribs} alt={alt} />
            ) : (
              <React.Fragment>
                <img {...imgAttribs} alt={alt} />
              </React.Fragment>
            )}
          </picture>
          {caption && (
            <div
              className="image-caption"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </Fragment>
      );
    }

    // Vertical image case
    const dimensions = {};
    if (defaultWidth && defaultHeight) {
      dimensions.width = defaultWidth;
      dimensions.height = defaultHeight;
    }
    return (
      <Fragment>
        <div className="picture">
          <img
            alt={alt}
            src={fcDomainAsset || src}
            data-src={fcDomainAsset || src}
            className={`${noLazy ? '' : 'lazyload'}`}
            {...dimensions}
          />
        </div>
        {caption && (
          <div
            className="image-caption"
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </Fragment>
    );
  }
}

Image.defaultProps = {
  noLazy: false,
  assetSizes: {},
  baseClass: '',
  modifier: '',
  jwId: '',
  aspectRatioImage: false,
  loading: '',
  verticalImg: false,
  defaultWidth: '',
  defaultHeight: '',
  caption: null,
  fetchpriority: 'auto'
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  baseClass: PropTypes.string,
  modifier: PropTypes.string,
  src: PropTypes.string.isRequired,
  noLazy: PropTypes.bool,
  assetSizes: PropTypes.shape({
    large: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.arrayOf(PropTypes.string)
    }),
    small: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  jwId: PropTypes.string,
  aspectRatioImage: PropTypes.bool,
  loading: PropTypes.string,
  verticalImg: PropTypes.string,
  defaultWidth: PropTypes.string,
  defaultHeight: PropTypes.string,
  caption: PropTypes.string,
  fetchpriority: PropTypes.string
};
