import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Eyebrow from '../../../eyebrow/eyebrow';
import Poster from '../../../poster/poster';
import { isAdvertorial, isPremium } from '../../../advertorial/advertorial';

export default function SmallCard(props) {
  const {
    metadata,
    config,
    cardEvent,
    disabledImage,
    fullPageAdvertorial,
    vertical,
    reverse,
    noBorder
  } = props;
  const advertorial = isAdvertorial(metadata.tags) && !fullPageAdvertorial;
  const isPremiumArticle = isPremium(metadata.tags || []);
  const image = metadata.featured_image.source;
  const hasImage = image && !disabledImage;

  const setCardClasses = () => {
    const cardClasses = ['card', 'card--small'];

    if (advertorial) {
      cardClasses.push('card--advertorial');
    }

    if (vertical) {
      cardClasses.push('vertical');
    }

    if (reverse) {
      cardClasses.push('reverse');
    }

    if (noBorder) {
      cardClasses.push('no-border');
    }

    return cardClasses.join(' ');
  };

  const setPosterClasses = () => {
    const posterClasses = ['card__poster', 'card__poster--small'];

    if (metadata.hasVideoPoster) {
      posterClasses.push('card__poster--video');
    }

    return posterClasses.join(' ');
  };

  return (
    <article className={setCardClasses()} onClick={cardEvent}>
      <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
      <FCLink
        currentSite={metadata.currentSite}
        to={metadata.link}
        title={metadata.title}
        config={config}
        externalLink={metadata.external}
        targetWindow={metadata.targetWindow}
      >
        <div className="card__text-wrapper card__text-wrapper--small">
          <h2 className="card__title card__title--small">{metadata.title}</h2>
        </div>
        {hasImage ? (
          <Poster
            metadata={metadata}
            className={setPosterClasses()}
            generateClasses={false}
          />
        ) : null}
      </FCLink>
    </article>
  );
}

SmallCard.defaultProps = {
  cardEvent: () => {},
  fullPageAdvertorial: false,
  disabledImage: false,
  vertical: false,
  reverse: false,
  noBorder: false
};

SmallCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  fullPageAdvertorial: PropTypes.bool,
  disabledImage: PropTypes.bool,
  vertical: PropTypes.bool,
  reverse: PropTypes.bool,
  noBorder: PropTypes.bool
};
