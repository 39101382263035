import React from 'react';
import PropTypes from 'prop-types';
import { isAdvertorial, isPremium } from '../../../advertorial/advertorial';
import FCLink from '../../../fc_link/fc_link';
import Eyebrow from '../../../eyebrow/eyebrow';

export default function RecircCard(props) {
  const { config, metadata, cardEvent } = props;
  const advertorial = isAdvertorial(metadata.tags);
  const isPremiumArticle = isPremium(metadata.tags || []);

  const setCardClasses = () => {
    const cardClasses = ['card', 'card--recirc'];

    if (advertorial) {
      cardClasses.push('card--advertorial');
    }

    return cardClasses.join(' ');
  };

  return (
    <article className={setCardClasses()} onClick={event => cardEvent(event)}>
      <FCLink
        to={metadata.link}
        title={metadata.title}
        config={config}
        externalLink={metadata.external}
        targetWindow={metadata.targetWindow}
      >
        <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
        <div className="card__title card__title--recirc">{metadata.title}</div>
      </FCLink>
    </article>
  );
}

RecircCard.defaultProps = {
  cardEvent: () => {}
};

RecircCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func
};
