import { isAdvertorial } from '../views/components/advertorial/advertorial';

export function setModifierClass(condition, baseClass, modifier) {
  if (!condition || !baseClass || !modifier) return '';
  return `${baseClass}--${modifier}`;
}

export function setElementClass(elementName, baseClass) {
  if (!elementName || !baseClass) return '';
  return `${elementName}__${baseClass}`;
}

export function createPosterClasses(tags, events, className, hasVideoPoster) {
  const posterClass = 'poster';
  const advertorial = setModifierClass(
    isAdvertorial(tags),
    posterClass,
    'advertorial'
  );
  const isEventCard = setModifierClass(events, posterClass, 'event-card');
  const hasClassName = setElementClass(className, posterClass);
  const requiresVideoOverlay = setModifierClass(
    hasVideoPoster,
    posterClass,
    'video'
  );
  const allClasses = [
    posterClass,
    advertorial,
    isEventCard,
    hasClassName,
    requiresVideoOverlay
  ];

  return allClasses.filter(element => element).join(' ');
}
