/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Poster from '../../../poster/poster';
import { getPriorityTag } from '../../../../../utils/ads/get_priority_tag';

class PromoCard extends React.Component {
  setCardClasses() {
    const { events } = this.props.metadata;
    const classNames = ['card', 'card--promo'];

    if (this.props.top) {
      classNames.push('card--top');
    }

    if (events) {
      classNames.push('card--event');
    }

    return classNames.join(' ');
  }

  setPosterClasses() {
    const { hasVideoPoster } = this.props.metadata;

    const posterClasses = ['card__poster', 'card__poster--default'];

    if (hasVideoPoster) {
      posterClasses.push('card__poster--video');
    }

    return posterClasses.join(' ');
  }

  setTextWrapperClass() {
    const event = this.props.metadata.events;
    const textWrapperClasses = ['card__text-wrapper'];

    if (!event) {
      textWrapperClasses.push('card__text-wrapper--default');
      if (this.props.align) {
        textWrapperClasses.push(
          `card__text-wrapper--align-${this.props.align}`
        );
      }
    }

    return textWrapperClasses.join(' ');
  }

  getText(tags, supertags) {
    const eyeBrowText = getPriorityTag(tags, supertags);
    const name = eyeBrowText.replace(/-/gi, ' ');

    if (!name) {
      return null;
    }

    const formattedName = name
      .replace(/\*/g, '')
      .replace(/_/g, ' ')
      .replace(/\s?advertorial/g, '')
      .trim();

    if (formattedName === 'usa can this brand be saved') {
      return 'USA: Can This Brand Be Saved?';
    }

    if (formattedName === 'LOreal') {
      return "L'Oreal";
    }

    if (formattedName === 'ATT') {
      return 'AT&T';
    }

    return formattedName;
  }

  render() {
    const { supertags } = this.props;
    const { cardType, categories } = this.props.metadata;
    const fcLink = this.props.metadata.link || this.props.metadata.url;
    return (
      <article
        className={this.setCardClasses()}
        onClick={event => this.props.cardEvent(event)}
      >
        <FCLink
          to={fcLink}
          title={this.props.metadata.title}
          config={this.props.config}
          metadata={this.props.metadata}
          cardType={cardType}
          externalLink={
            this.props.metadata.external ||
            this.props.metadata.source === 'external'
          }
          targetWindow={this.props.metadata.targetWindow}
        >
          <Poster
            metadata={this.props.metadata}
            className={this.setPosterClasses()}
            generateClasses={false}
          />
          <div className={this.setTextWrapperClass()}>
            <h1 className="card__title card__title--byline">
              Presented by{' '}
              {categories[0]?.name.toLowerCase() === 'advertorial'
                ? this.getText(this.props.metadata.tags || [], supertags)
                : categories[0]?.name || ''}
            </h1>
            <h2 className="card__title card__title--collection-text ">
              {this.props.metadata.title}
            </h2>
          </div>
        </FCLink>
      </article>
    );
  }
}

PromoCard.defaultProps = {
  cardEvent: () => {},
  align: null,
  top: false
};

PromoCard.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  cardEvent: PropTypes.func,
  align: PropTypes.string,
  top: PropTypes.bool
};

export default PromoCard;
