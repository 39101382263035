import React from 'react';
import PropTypes from 'prop-types';
import FCLink from '../../../fc_link/fc_link';
import Poster from '../../../poster/poster';
import Eyebrow from '../../../eyebrow/eyebrow';
import { isAdvertorial, isPremium } from '../../../advertorial/advertorial';

export default function AllFeed(props) {
  const { config, top, metadata, cardEvent, fullPageAdvertorial } = props;
  const searchAdvertorial =
    metadata.category && metadata.category.name === 'Advertorial';
  const advertorial =
    (isAdvertorial(metadata.tags) || searchAdvertorial) && !fullPageAdvertorial;
  const isPremiumArticle = isPremium(metadata.tags || []);

  const setCardClasses = () => {
    const cardClasses = ['card', 'card--all-feed'];

    if (advertorial) {
      cardClasses.push('card--advertorial');
    }

    if (top) {
      cardClasses.push('card--top');
    }

    return cardClasses.join(' ');
  };

  const setPosterClasses = () => {
    const posterClasses = ['card__poster', 'card__poster--all-feed'];

    if (metadata.hasVideoPoster) {
      posterClasses.push('card__poster--video');
    }

    return posterClasses.join(' ');
  };

  const hasImage =
    metadata.featured_image.source && metadata.layout !== 'short';

  return (
    <article
      className={setCardClasses('card')}
      onClick={event => cardEvent(event)}
    >
      <Eyebrow metadata={metadata} showIcon={isPremiumArticle} />
      <FCLink
        currentSite={metadata.currentSite}
        to={metadata.link}
        title={metadata.title}
        config={config}
        externalLink={metadata.external}
        targetWindow={metadata.targetWindow}
      >
        <div className="card__text-wrapper card__text-wrapper--all-feed">
          <h2 className="card__title card__title--all-feed">
            {metadata.title}
          </h2>
          {hasImage ? (
            <h3 className="card__deck card__deck--all-feed">
              {metadata.excerpt}
            </h3>
          ) : null}
        </div>
        <Poster
          metadata={metadata}
          className={setPosterClasses()}
          generateClasses={false}
        />
      </FCLink>
    </article>
  );
}

AllFeed.defaultProps = {
  top: false,
  cardEvent: () => {},
  fullPageAdvertorial: false
};

AllFeed.propTypes = {
  config: PropTypes.shape({}).isRequired,
  top: PropTypes.bool,
  metadata: PropTypes.shape({}).isRequired,
  fullPageAdvertorial: PropTypes.bool,
  cardEvent: PropTypes.func
};
