import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DefaultCard from './cards/default_card/default_card';
import FeaturedCard from './cards/featured_card/featured_card';
import AllFeedCard from './cards/all_feed_card/all_feed_card';
import VideoCard from './cards/video_card/video_card';
import RecircCard from './cards/recirc_card/recirc_card';
import SmallCard from './cards/small_card/small_card';
import MagazineCard from './cards/magazine_card/magazine_card';
import InjectionCard from './cards/injection_card/injection_card';
import GiftCard from './cards/gift_card/gift_card';
import PromoCard from './cards/promo_card/promo_card';
import AudioCard from './cards/audio_card/audio_card';

export default function Card(props) {
  const { type, metadata } = props;
  if (isEmpty(metadata)) {
    return null;
  }
  switch (type) {
    case 'featured':
      return <FeaturedCard {...props} />;
    case 'video':
      return <VideoCard {...props} />;
    case 'all-feed':
      return <AllFeedCard {...props} />;
    case 'audio':
      return <AudioCard {...props} />;
    case 'recirc':
      return <RecircCard {...props} />;
    case 'small':
      return <SmallCard {...props} />;
    case 'magazine':
      return <MagazineCard {...props} />;
    case 'injection':
      return <InjectionCard {...props} />;
    case 'gift':
      return <GiftCard {...props} />;
    case 'promo':
      return <PromoCard {...props} />;
    default:
      return <DefaultCard {...props} />;
  }
}

Card.defaultProps = {
  type: ''
};

Card.propTypes = {
  type: PropTypes.string,
  metadata: PropTypes.shape({}).isRequired
};
